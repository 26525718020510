import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Gummy Smile - Laser Gum Surgery | Gum Lift Toronto | The Smile Stylist" 
    description="You can now improve your gummy smile with gingivectomy.  The Smile Stylist Cosmetic Dentistry uses lasers to re-shape your gums and lengthen your smile." 
    keywords="Gummy smile, gum lift Toronto, laser gum surgery" />
    <main className="site-content" role="main">
    <section id="main-top-img" className="gum-lift hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h1>Gum Lift Procedures</h1>
            <h2>Also known as "gingivectomy"</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="main-top-img" className="gum-lift hidden-sm hidden-md hidden-lg">
      <div className="container">
        <div className="row">
          <p>&nbsp;</p>
        </div>
      </div>
    </section>
    <section id="top-mobile" className="hidden-sm hidden-md hidden-lg">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h2>Gum Lift Procedures</h2>
            <h2>Also known as "gingivectomy"</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <p><h2><a href="/gum-lift-procedures">Gum Lift Procedures</a></h2></p>
            <p className="lead-black-bold">Gum lifting, also known as "gingivectomy", is a cosmetic dentistry procedure which improves the aesthetics of your smile by ensuring the gum line of your teeth is in the best possible position to show off your teeth.</p>
            <p>It is usually done in conjunction with other cosmetic treatments, such as veneers or crowns. The aim of a gum lift is to restructure excessive or uneven gum tissue surrounding your teeth so your gums are symmetrical and in proportion to your smile, rather than having a "gummy smile".</p>
            <p>The procedure is quick and relatively painless. The gum area around the front teeth is usually the only area corrected, as this is the most visible section of your smile. Your Smile Stylist will cut into and reshape the unsightly gum area, using a soft tissue laser to seal off the blood vessels and minimize any bleeding.</p>
            <p>There are typically no stitches involved, and the procedure is usually very quick and relatively painless. You should be able to eat and drink within 24 hours after surgery, and your gums will heal completely within the week after.</p>
            <p>Any pain after the procedure can be controlled using over the counter medicines, and should only be necessary immediately after the surgery.</p>
            <p>If the gum lift is undergone at the same time as other cosmetic surgery, recovery time may be longer. Your Smile Stylist will advise you on expected post-surgery recovery time. To ensure best long term results, you will be advised to attend regular follow up appointments and follow prescribed at-home oral hygiene instructions. This will help prevent infections, further gum recession and keep your new smile looking healthy and clean.</p>
            <p>Any surgical or invasive procedure carries risks. Before proceeding with a surgical or invasive procedure, let us help you decide if a gum lift is right for you. Use our <a href="/virtual-smile-makeover">VIRTUAL SMILE MAKEOVER</a> to see what your new smile online!</p>
            <p><a className="btn btn-red" href="/contact">Book Your Appointment</a></p>
          </div>
          <div className="col-sm-4 wow animated zoomIn text-center service__shadow-imgs" data-wow-delay="0.4s">
            <p>
              <img src={ require("../images/desktop/services/GumLift1.jpg" )} alt="Gum Lifting " className="img-thumbnail" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/GumLift2.jpg" )} alt="Gum Lifting Treatment " className="img-thumbnail" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/ba-gum.jpg" )} title="Gum Lift + Porcelain Veneers" alt="Gum Lifting and Veneers Treatment " className="img-thumbnail" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/ba-veneers1.jpg" )} title="Gum Lift + Porcelain Veneers" alt="Gum Lifting Before and After " className="img-thumbnail" />
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage